var exports = $.extend({}, require('core/search/search'));
var SiteConstants = require('constants/SiteConstants');

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 1;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

var setRefinementCollapseStates = status => {

    if (status === 'open') {
        $('.refinement').addClass('active');
    }
    else if (status === 'closed') {
        $('.refinement').removeClass('active');
        $('.refinement').find('button.title').attr('aria-expanded','false');
    }
};

var toggleRefinementDrawer = status => {
    var $refinementBar = $('.refinement-bar');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        var $header = $('header');
        var headerNavHeight = $header.outerHeight();
        var scrollTopHeight = $header.offset().top;
        $('html').scrollTop(scrollTopHeight);
        // Following two lines for MS Edge to work
        document.body.scrollTop = scrollTopHeight;
        document.documentElement.scrollTop = scrollTopHeight;
        $('html').addClass('lock-scroll');
        $('body').addClass('mobile-filter-drawer-in');
        $refinementBar.addClass('in').css('top', headerNavHeight).siblings().attr('aria-hidden', true);
        $modalBackground.fadeIn(SiteConstants.TransitionSpeed).css('top', headerNavHeight);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', true);
        $refinementBar.find('.close').focus();
    } else {
        $('html').removeClass('lock-scroll');
        $('body').removeClass('mobile-filter-drawer-in');
        $refinementBar.removeClass('in').css('top', '').siblings().attr('aria-hidden', false);
        $modalBackground.fadeOut(SiteConstants.TransitionSpeed);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    }
};

var toggleFilters = (e) => {
    e.preventDefault();
    var toggleDirection = 'open';
    if ($('.refinement-bar .refinement').hasClass('active')) {
        toggleDirection = 'closed';
    }
    setRefinementCollapseStates(toggleDirection);
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
        },
        complete: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response, promoTileCount) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    var pageSize = Number($tempDom.find('.grid-footer').data('page-size'));

    sortOptions.forEach(option => {
        var pageUrl = new URL(window.location);
        var urlPageSize = pageUrl.searchParams.get('sz') !== null ? Number(pageUrl.searchParams.get('sz')) : null;
        var optionUrl = new URL(option.url);
        var optionPageSize = Number(optionUrl.searchParams.get('sz'));

        // If 'sz' is already defined in URL, but promo tiles aren't already accounted for, decrement sz param
        if (!isNaN(urlPageSize) && urlPageSize !== optionPageSize - pageSize) {
            var updatedPageSize = optionPageSize - promoTileCount;
            optionUrl.searchParams.set('sz', updatedPageSize);
            option.url = optionUrl.toString();
        }

        $('option.' + option.id).val(option.url);
    });
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results, selector) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });
    updateDom($results, selector);
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response, openedDropdownID) {
    var $results = $(response);
    var isMobile = window.isMobile();
    if (isMobile) {
        var $refinements = $results.find('.refinements[data-refinement-style-mobile]');
        var refinementStyle = $refinements.data('refinement-style-mobile');
        var selector = `[data-refinement-style-mobile="${refinementStyle}"]`;
    } else {
        var $refinements = $results.find('.refinements[data-refinement-style-desktop]');
        var refinementStyle = $refinements.data('refinement-style-desktop');
        var selector = `[data-refinement-style-desktop="${refinementStyle}"]`;
    };

    var $filterBar = $results.find('.filter-bar');
    var filterBarStyle = !isMobile ? $filterBar.attr('data-filter-bar-style-desktop') : $filterBar.attr('data-filter-bar-style-mobile');
    var filterBar = !isMobile ? '[data-filter-bar-style-desktop="' + filterBarStyle + '"]' : '[data-filter-bar-style-mobile="' + filterBarStyle + '"]';

    var verticalMobile = isMobile && selector === '[data-refinement-style-mobile="vertical"]';
    var verticalDesktop = !isMobile && selector === '[data-refinement-style-desktop="vertical"]';
    var horizontalMultipleDropdownsDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-multiple-dropdowns"]';
    var horizontalOneDropdownDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-one-dropdown"]';
    var horizontalOneDropdownDesktopActive = $('.collapse-filters-wrapper').hasClass('active');
    var horizontalOneDropdownDesktopOpen = false;

    var filterDrawer = $('.vertical-filter-drawer');
    var filterDrawerActive = $('.vertical-filter-drawer').hasClass('active');
    var filterDrawerOpen = false;
    var openAttributeIDs = [];
    if (filterDrawer && filterDrawerActive && filterDrawer.children().length > 0) {
        filterDrawerOpen = true;
        // find currently expanded attributes
        var openAttributes = filterDrawer.find('.collapse.show');
        openAttributes.each(function () {
            openAttributeIDs.push($(this).attr('id'));
        });
    }

    if (horizontalOneDropdownDesktop && horizontalOneDropdownDesktopActive) {
        horizontalOneDropdownDesktopOpen = true;
    }

    if (verticalMobile || verticalDesktop) {
        var specialHandlers = {
            selector : handleRefinements
        };

        Object.keys(specialHandlers).forEach(function (selector) {
            specialHandlers[selector]($results, selector);
        });
    };

    if (!isMobile) {
        var refinements = '.desktop-search-refinements';
    } else {
        var refinements = '.mobile-search-refinements';
    };
    var overflowRefinements = '.vertical-filter-drawer';

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        refinements,
        overflowRefinements,
        filterBar
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    // reopen selected/clicked dropdrown after DOM update
    if (openedDropdownID && horizontalMultipleDropdownsDesktop) {
        $('button#' + openedDropdownID).dropdown('toggle');
    }

    if (horizontalOneDropdownDesktopOpen === true) {
        $('.collapse-filters-wrapper').addClass('active').css('display', 'block');
    }

    // close filter drawer if no result found
    if ($(overflowRefinements).children().length <= 0) {
        $(overflowRefinements).removeClass('active');
    } else if (filterDrawerOpen === true) {
        // re-expand selected attributes
        $('.vertical-filter-drawer').addClass('active');
        openAttributeIDs.forEach(function(selector) {
            var $refinementGroup = $(overflowRefinements + ' #' + selector);
            var $refinementTitle = $('[aria-controls="'+selector+'"]');
            $refinementGroup.addClass('show');
            $refinementTitle.attr('aria-expanded', 'true');
        });

    }

    $('body').trigger('ajax:load.ajaxEvents', [$('.search-results')]);
    $('body').trigger('search:updateCount');
    exports.refinementStickyBar();
    exports.oneDropdownSlideToggle();
}

exports.oneDropdownSlideToggle = function() {
    var oneDropdownFilter = $('.horizontal-onedropdown-filter');
    if (oneDropdownFilter) {
        var menuToggle = $('.horizontal-onedropdown-filter-btn').unbind();
        menuToggle.removeClass('open');

        menuToggle.on('touchstart click', function(e) {
            e.preventDefault();
            $('.collapse-filters-wrapper').slideToggle(SiteConstants.TransitionSpeed).toggleClass('active');
            menuToggle.toggleClass('open');
        });
    }
}

exports.refinementStickyBar = function() {
    var showStickyRefinementData = window.isMobile() ? document.getElementById('horizontal-filter-mobile') : document.getElementById('horizontal-filter');
    if (showStickyRefinementData) {
        var showStickyRefinementBarCheck = showStickyRefinementData.getAttribute('data-sticky-refinement-bar');
        var mainContent = document.getElementById('maincontent');
        var topHeaderHeight = 0;
        var heightCalculation = document.querySelector('.search-results').offsetTop;

        if (showStickyRefinementData && showStickyRefinementBarCheck == 'true') {
            // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
            var lastPosition = window.pageYOffset;
            window.addEventListener('scroll', function() {
                //main sticky horizontal dropdown filter functionality - only show when scrolled past in-page refinement button section
                if ((window.pageYOffset > heightCalculation && mainContent.scrollHeight > (window.innerHeight + showStickyRefinementData.offsetHeight)) && lastPosition - window.pageYOffset > 0) {
                    showStickyRefinementData.classList.add('sticky-filters');
                    showStickyRefinementData.setAttribute('style', 'top:' + topHeaderHeight + 'px;');
                    mainContent.setAttribute('style', 'padding-top:0px;');
                } else if (showStickyRefinementData.classList.contains('sticky-filters') && window.pageYOffset < heightCalculation || (lastPosition - window.pageYOffset < 0)) {
                    showStickyRefinementData.classList.remove('sticky-filters');
                    mainContent.removeAttribute('style');
                }
                lastPosition = window.pageYOffset;
            });
        }
    }

}

var closeOneRefinementsDropdown = (e) => {
    var $oneRefinementsDropdown = $('.horizontal-onedropdown-filter-group');
    if ((!$('.collapse-one-dropdown-filter').is(e.target) && $oneRefinementsDropdown.has(e.target).length === 0)
        || $('.close-one-refinements-dropdown').is(e.target)) {
            $('.collapse-filters-wrapper').slideUp().removeClass('active');
    }
};

exports.initialize = () => $(document).ready(function () {
    var isMobile = window.isMobile();
    var collapseMobile = $('[data-collapse-mobile]').attr('data-collapse-mobile') === 'true' ? true : false;
    var collapseDesktop = $('[data-collapse-desktop]').attr('data-collapse-desktop') === 'true' ? true : false;
    if (isMobile && collapseMobile) {
        setRefinementCollapseStates('close');
    } else if (isMobile && !collapseMobile) {
        setRefinementCollapseStates('open');
    } else if (!isMobile && collapseDesktop) {
        setRefinementCollapseStates('close');
    } else if (!isMobile && !collapseDesktop) {
        setRefinementCollapseStates('open');
    }
    if ($('#articles-tab').hasClass('active') && $('#content-search-results').html() === '') {
        getContent($('.content-search'), $('#content-search-results'));
    }

    var refinementCategoryOpen = localStorage.getItem('refinement-category');
    if (refinementCategoryOpen) {
        $('.refinement-category').addClass('active');
    }
    $('body').trigger('search:updateCount');
});

exports.closeRefinements = function () {

    $('html').on('click', '.refinement-bar button.close, .mobile-filter-drawer-in .modal-background', () => toggleRefinementDrawer('close'));
    //for horizontal one dropdown menu
    $('html').on('click', (e) => closeOneRefinementsDropdown(e));
}

exports.sort = function () {
    var $xhr;
    // Handle sort order menu selection
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var thisValue = this.value;
        $(this).trigger('search:sort', this.value);
        var urlParams = new URLSearchParams($(this).val().split('?')[1]);
        var sortOrder = urlParams.get('srule');

        const newUrl = new URL(window.location);
        newUrl.searchParams.set("srule", sortOrder);

        var newSelection = this.selectedOptions[0];
        $('[name="sort-order"]').find('option[selected="selected"], option:selected').prop('selected',false).removeAttr('selected');
        $(newSelection).prop('selected', true).attr('selected', true);

        // Cancel previous request
        $xhr && $xhr.abort && $xhr.abort();

        $xhr = $.ajax({
            url: thisValue + '&isAjax=true',
            data: {
                selectedUrl: thisValue,
                isSortUpdate: true
            },
            method: 'GET',
            timeout: 10000,
            beforeSend: function () {
                $.spinner().start();
            },
            success: function (response) {
                var $result = $(response)
                var $productGridElement = $result.find('.product-grid'); // if response is coming from page designer
                var updatedGridHtml = $productGridElement.length > 0 ? $productGridElement.children() : response;
                $('.product-grid').empty().html(updatedGridHtml);
                affirm && affirm.ui.ready(function () {
                    affirm.ui.refresh();
                });
                

                // update page size dropdown url params to selected sorting rule
                $('select[name="page-size"] option').each((i, el) => {
                    if ($(el).attr('value') && newUrl) {
                        let pageOptionUrl = $(el).attr('value').startsWith('http') ? $(el).attr('value') : window.location.origin + $(el).attr('value');
                        let pageOptionUrlObj = new URL(pageOptionUrl);
                        pageOptionUrlObj.searchParams.set("srule", sortOrder);
                        $(el).attr('value', pageOptionUrlObj.toString());
                    }
                });

                history.replaceState(undefined, '', newUrl);
                var gtmData = $result.filter('input#viewcat-gtmdata');
                if (gtmData.length) {
                    gtmData = gtmData.val();
                    if (gtmData){
                        $('body').trigger('gtm:pushData', [gtmData, true]);
                    }
                }
                var searchSort = $result.filter('input#viewSearch-sortdata');
                if (searchSort.length) {
                    searchSort = searchSort.val();
                    if (searchSort){
                        $('body').trigger('gtm:pushData', [searchSort, true]);
                    }
                }
                $('body').trigger('search:sort--success');
                $('body').trigger('ajax:load.ajaxEvents', [$('.product-grid')]);
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.pageSize = function () {
    var $xhr;
    // Handle page size dropdown menu selection
    $('.container').on('change', '[name=page-size]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var thisValue = this.value;
        var urlParams = new URLSearchParams(thisValue.split('?')[1]);
        var selectedStartIndex = urlParams.get('start');
        var selectedSize = urlParams.get('sz');

        const newUrl = new URL(window.location);
        newUrl.searchParams.set("start", selectedStartIndex);
        newUrl.searchParams.set("sz", selectedSize);

        var newSelection = this.selectedOptions[0];
        $('[name="page-size"]').find('option[selected="selected"], option:selected').prop('selected', false).removeAttr('selected');
        $(newSelection).prop('selected', true).attr('selected', true);

        // Cancel previous request
        $xhr && $xhr.abort && $xhr.abort();

        $xhr = $.ajax({
            url: thisValue + '&isAjax=true',
            data: {
                selectedUrl: thisValue
            },
            method: 'GET',
            timeout: 10000,
            beforeSend: function () {
                $.spinner().start();
            },
            success: function (response) {
                var $productGridElement = $(response).find('.product-grid'); // if response is coming from page designer
                var updatedGridHtml = $productGridElement.length > 0 ? $productGridElement.children() : response;
                $('.product-grid').empty().html(updatedGridHtml);
                affirm && affirm.ui.ready(function () {
                    affirm.ui.refresh();
                });

                // update sort dropdown url params to selected page size
                $('select[name="sort-order"] option').each((i, el) => {
                    if ($(el).attr('value')) {
                        let sortOptionUrl = $(el).attr('value').startsWith('http') ? $(el).attr('value') : window.location.origin + $(el).attr('value');
                        let sortOptionUrlObj = new URL(sortOptionUrl);
                        sortOptionUrlObj.searchParams.set("start", selectedStartIndex);
                        sortOptionUrlObj.searchParams.set("sz", selectedSize);
                        $(el).attr('value', sortOptionUrlObj.toString());
                    }
                });

                history.replaceState(undefined, '', newUrl);
                var gtmData = $(response).filter('input#viewcat-gtmdata');
                if (gtmData.length) {
                    gtmData = gtmData.val();
                    if (gtmData){
                        $('body').trigger('gtm:pushData', [gtmData, true]);
                    }
                }
                var productsPerPage = $(response).filter('input#viewSearch-productsPerPage');
                if (productsPerPage.length) {
                    productsPerPage = productsPerPage.val();
                    if (productsPerPage){
                        $('body').trigger('gtm:pushData', [productsPerPage, true]);
                    }
                }
                $('body').trigger('search:pageSize--success');
                $('body').trigger('ajax:load.ajaxEvents', [$('.product-grid')]);
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.showMore = function () {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var showMoreUrl = $(this).data('url');
        var $showMoreButton = $(e.target);
        $.spinner().start();
        $(this).trigger('search:showMore', e);

        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                var promoTileCount = $showMoreButton.closest('.product-grid').find('[class*="experience-promoTile"]').length;
                $('.grid-footer').replaceWith(response);
                updateSortOptions(response, promoTileCount);
                $('body').trigger('search:showMore--success');

                // get total product counts for new page load and replace current url to contain the counts without refreshing,
                // so when redirected and navigated back it would auto scroll to previous position
                let showMoreUrlObject = new URL(showMoreUrl);
                let currentProductsCount = showMoreUrlObject.searchParams.get('start'); // get current products count
                let newProductsCount = showMoreUrlObject.searchParams.get('sz');    // get the newly added products' count
                let newTotalProductCount = parseInt(currentProductsCount || 0) + parseInt(newProductsCount || 24);
                // get current url's params and update/set "sz" param
                let currentUrl = new URL(window.location.href);
                let currentUrlParams = currentUrl.searchParams;
                currentUrlParams.set('sz', newTotalProductCount);
                // replace current url to a new url with updated params
                history.replaceState({}, null, `${currentUrl.search}`);

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.applyFilter = function () {
    var $xhr;
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .filter-bar button.reset, .filter-value button, .swatch-filter button, .price-range-btn',
        function (event) {
			const currentUrl = new URLSearchParams(window.location.search);
            var category = $(event.currentTarget).closest('.refinement');
            var openedDropdownID = $(event.currentTarget).closest('.dropdown-menu').attr('aria-labelledby');
            var desktopLayout = $('.refinements .grid-layout-toggle').attr('data-selected-desktop');
            var mobileLayout = $('.refinements .grid-layout-toggle').attr('data-selected-mobile');
            if (category && category.hasClass('refinement-category')) {
                localStorage.setItem('refinement-category', true);
            }
            //find new attr on Sort if selected, if not use the default
            var selectedSort = $('select[name="sort-order"]').find('option[selected="selected"]');
            if (selectedSort.data('id') != null) {
                var sortingRule = selectedSort.data('id');
            } else {
                var sortingRule = $('select[name="sort-order"] option:selected').data('id');
            }

            // find current paging size option, if not use the default
            var selectedPageSize = $('select[name="page-size"]').find('option[selected]');
            if ($.trim(selectedPageSize.text()) != null) {
                var selectedPageSizeValue = $.trim(selectedPageSize.text());
            } else {
                var selectedPageSizeValue = $.trim($('select[name="page-size"] option:selected').text());
            }

            //create a new url with the correct preferences
            if ($(this).data('href').includes('srule')) {
                var refinementUrl = $(this).data('href').replace(/(srule=).*?(&|$)/,'$1' + sortingRule + '$2');
            } else if (!$(this).data('href').includes('srule') && sortingRule != null) {
                var split = $(this).data('href').indexOf('?') !== -1 ? '&' : '?';
                var refinementUrl = $(this).data('href') + split + 'srule=' + sortingRule;
            } else {
                var refinementUrl = $(this).data('href');
            }
            // append/update page size to the new url
            if (refinementUrl.includes('sz')) {
                refinementUrl = refinementUrl.replace(/(sz=).*?(&|$)/, '$1' + selectedPageSizeValue + '$2');
            } else if (!refinementUrl.includes('sz') && selectedPageSizeValue != null) {
                var split = refinementUrl.indexOf('?') !== -1 ? '&' : '?';
                var refinementUrl = refinementUrl + split + 'sz=' + selectedPageSizeValue;
            }
            // Append grid layout to new URL
            if (currentUrl.has('gridLayout')) {
                refinementUrl = refinementUrl + '&gridLayout=' + currentUrl.get('gridLayout');
            }
            event.preventDefault();
            event.stopPropagation();
            $(this).trigger('search:filter', event);

            // Cancel previous request
            $xhr && $xhr.abort && $xhr.abort();

            $xhr = $.ajax({
                url: refinementUrl + '&isAjax=true',
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: refinementUrl
                },
                method: 'GET',
                timeout: 10000,
                beforeSend: function () {
                    $.spinner().start();
                },
                success: function (response) {
                    module.exports.methods.parseResults(response, openedDropdownID);
                    affirm && affirm.ui.ready(function () {
                        affirm.ui.refresh();
                    });
                    history.replaceState(undefined, '', refinementUrl);
                    $('.grid-layout-toggle').attr('data-selected-desktop', desktopLayout);
                    $('.grid-layout-toggle').attr('data-selected-mobile', mobileLayout);
                    $('body').trigger('search:filter--success');
                    var $searchGTMelement= $(response).filter('input#search-gtmdata');
                    if($searchGTMelement.length){
                        var searchGTMData = $searchGTMelement.val();
                        if(searchGTMData){
                            $('body').trigger('gtm:pushData',[searchGTMData , true]);
                        }
                    }
                    var $searchFilterEvent= $(response).filter('input#search-filter');
                    if($searchFilterEvent.length){
                        var searchFilterData = $searchFilterEvent.val();
                        if(searchFilterData){
                            $('body').trigger('gtm:pushData',[searchFilterData , true]);
                        }
                    }
                    exports.priceRangeFilter();
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        });
};

exports.storeFilter = function () {
    $('body').on('click', '.refine-by-store', function (e) {
        var storeID = $(this).data('store-id');
        if (storeID) {
            var $storeFilter = $(`#instoredisplaystores ul.values li[data-value='${storeID}']`);
            if($storeFilter.length > 0) {
                $storeFilter.find('button').trigger('click');
            }
        }
    });
};

exports.priceRangeFilter = function () {
    var baseUrl = $('.price-range-btn').attr('data-base-url');
    var actionUrl = $('.price-range-btn').attr('data-href');
    var append = baseUrl && actionUrl && (baseUrl.length > 0 && baseUrl === actionUrl && baseUrl.indexOf('?') === -1) ? '?' : '&';

    var minPrice = $.trim($('#price-min').val()).length ? parseFloat($.trim($('#price-min').val())) : 0;
    var maxPrice = $.trim($('#price-max').val()).length ? parseFloat($.trim($('#price-max').val())) : 0;
    if (maxPrice > minPrice) {
        $('.price-range-btn').attr('data-href', actionUrl + append + 'pmin=' + minPrice.toString() + '&pmax=' + maxPrice.toString());
        $('.price-range-btn').prop('disabled', false);
    }

    $('#price-min').on('input', function() {
        if (/^\d+\.?\d{0,2}$/.test($.trim($(this).val()))) {
            var minPrice = $.trim($(this).val()).length ? parseFloat($.trim($(this).val())) : 0;
            var maxPrice = $.trim($('#price-max').val()).length ? parseFloat($.trim($('#price-max').val())) : 0;
            console.log(maxPrice + '>' + minPrice + ': ' + (maxPrice > minPrice));
            if (maxPrice > minPrice) {
                $('.price-range-btn').attr('data-href', actionUrl + append + 'pmin=' + minPrice.toString() + '&pmax=' + maxPrice.toString());
                $('.price-range-btn').prop('disabled', false);
            } else {
                $('.price-range-btn').prop('disabled', true);
            }
        }
    });

    $('#price-max').on('input', function() {
        if (/^\d+\.?\d{0,2}$/.test($.trim($(this).val()))) {
            var maxPrice = $.trim($(this).val()).length ? parseFloat($.trim($(this).val())) : 0;
            var minPrice = $.trim($('#price-min').val()).length ? parseFloat($.trim($('#price-min').val())) : 0;
            console.log(maxPrice + '>' + minPrice + ': ' + (maxPrice > minPrice));
            if (maxPrice > minPrice) {
                $('.price-range-btn').attr('data-href', actionUrl + append + 'pmin=' + minPrice.toString() + '&pmax=' + maxPrice.toString());
                $('.price-range-btn').prop('disabled', false);
            } else {
                $('.price-range-btn').prop('disabled', true);
            }
            if (!minPrice) {
                $('#price-min').val('0');
            }
        }
    });

    $('.price-range-clear').on('click', function() {
        $('.price-range-btn').prop('disabled', true);
        $('#price-min').val('');
        $('#price-max').val('');
    })
}

exports.setChecked = function () {
    $('body').on('search:filter--success', function () {
        $('.dropdown .selected').each((index, ele) => {
            $(ele).closest('.category-button').attr('checked', true);
        })
    })
}

exports.fromPricing = function () {
    if ($('.search-results').data('from-pricing')) {
        $('.sales .display-price-from').show();
        $('.standard-from-pricing').hide();
    }
}

exports.selectedRefinementCount = function () {
    $('body').on('search:updateCount', function() {
        var $searchContainer = $('.search-results');
        var $refinementNodes = $searchContainer.find('.js-refinement');
        $refinementNodes.each((index, ele) => {
            var $selectedAttributesNodes = $(ele).find('button .fa-check-circle, button .fa-check-square');
            var $label = $(ele).find('.js-refinement-label');
            if ( $selectedAttributesNodes.length > 0 ) {
                $label.append("(" + $selectedAttributesNodes.length +")");
                $label.addClass('font-weight-bold');
            } else {
                $label.removeClass('font-weight-bold');
            }
        });

        //More Filter Count
        var $moreFilterButton = $('.js-morefilters');
        var $selectedOverflowAttributes = $('.overflow-search-refinements .js-refinement:not(.d-lg-none) .fa-check-circle, .overflow-search-refinements .js-refinement:not(.d-lg-none) .fa-check-square');
        if ($selectedOverflowAttributes.length > 0) {
            $moreFilterButton.append(" (" + $selectedOverflowAttributes.length + ")");
            $moreFilterButton.addClass('font-weight-bold');
        } else {
            $moreFilterButton.removeClass('font-weight-bold');
        }
    })
}

exports.expandSelectedFilters = function () {
    $('body').on('click', '.selected-filters-toggle', (element) => {
        $(element.currentTarget).hide();
        $('.more-selected-filters').show();
    });
}

exports.productViewData = function () {
    $(document).on('click', '.layout-desktop-grid, .layout-mobile-grid, .layout-desktop-list, .layout-mobile-block', function(e) {
        var productViewObj = {
            'event': 'product_view',
            'product_view_method': $(this).val()
        };
        $('body').trigger('gtm:pushData', [productViewObj, true]);
    });
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response).show();

        //For accessability
        if (window.isMobile()) {
            setTimeout(function () {
                $suggestionsWrapper.find('.describedBy').attr('id', $suggestionsWrapper.attr('data-describedby'));
            }, 10);
        }

        $(this).siblings('.reset-button').addClass('d-sm-block');
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                getSuggestionsWrapper(scope).find('span.sr-only').text('Zero Results Found');
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').removeClass('d-sm-block');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }

    $('.site-search .reset-button').on('click', function () {
        $(this).removeClass('d-sm-block');
        getSuggestionsWrapper(scope).empty();
    });
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

exports.searchSuggestionsOverride = function () {
    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });
}

exports.filter = () => $('html').on('click', 'button.filter-results', () => toggleRefinementDrawer('open'));
exports.toggle = () => $('html').on('click', '.js-toggle-filters', (e) => toggleFilters(e));
exports.reset = () => $('html').on('click', '.js-reset', (e) => $('.refinements li button').trigger('click'));
exports.resize = () => $(window).resize(() => {toggleRefinementDrawer('close'); exports.oneDropdownSlideToggle();});

exports.methods = {
    parseResults: parseResults
}
module.exports = exports;
